<template>
  <div>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div id="EmployeesAccount">
      <div class="EmployeesAccountTitle">
        <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("库存报告") }}</div>
        <div class="flexAndCenter">
          <div class="fontSize14" style="margin-right: 12px">{{ $fanyi("最终同步时间") }}：{{ time }}</div>
          <div @click="synchronization">{{ $fanyi("同步库存") }}</div>
        </div>
      </div>
      <div class="flexAndCenter orderScreenContainer">
        <div class="flexAndCenter">
          <div class="orderKeywordsContainer">
            <el-input v-model="form.keywords" :placeholder="$fanyi('请输入关键词')" style="width: 160px;"/>
          </div>
          <div class="orderKeywordsContainer" style="margin-left: 10px">
            <el-input v-model="form.sku" placeholder="FNSKU" style="width: 160px;"/>
          </div>
          <div class="orderKeywordsContainer" style="margin-left: 10px">
            <el-select v-model="form.status">
              <el-option
                  v-for="item in statusOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="orderScreenBtnListContainer" style="margin-left:26px">
            <div @click="getData(form)">{{ $fanyi("筛选") }}</div>
          </div>
        </div>
        <div class="orderScreenBtnListContainer" @click="placeAnOrder">
          <div>{{ $fanyi("提出订单") }}</div>
        </div>
      </div>
      <div class="dataBody">
        <ul class="head flexAndCenter">
          <li class="oNO" style="line-height: normal">
            <div class="flexAndCenterAndCenter" style="height: 56px">
              <input v-model="checked" style="margin-right: 5px" type="checkbox"
                     @change="checkedChange"/>
              <span :title="$fanyi('状态')" class="tableHeadCon">{{
                  $fanyi('状态')
                }}</span>
            </div>
          </li>
          <li class="pic">
            <span :title="$fanyi('照片')" class="tableHeadCon">{{ $fanyi("照片") }}</span>
          </li>
          <li class="submitTime">
            <span :title="$fanyi('商品名称')" class="tableHeadCon">{{ $fanyi("商品名称") }}</span>
          </li>
          <li class="datePresentation">
            <span :title="$fanyi('商品SKU')" class="tableHeadCon">{{ $fanyi("商品SKU") }}</span>
          </li>
          <li class="datePresentation">
            <span :title="$fanyi('ASIN/FNSKU')" class="tableHeadCon">{{ $fanyi("ASIN/FNSKU") }}</span>
          </li>
          <li class="receiverName">
            <span :title="$fanyi('可售量')" class="tableHeadCon">{{ $fanyi("可售量") }}</span>
          </li>
          <li class="replenishmentLink" style="background: #FAF2F2;">
            <span :title="$fanyi('补货链接')" class="tableHeadCon">{{ $fanyi("补货链接") }}</span>
          </li>
        </ul>
        <div v-show="tableData.length > 0">
          <div v-for="(item, index) in tableData" :key="$fanyi('表格') + index"
               style="border-bottom: 1px solid #DFDFDF;">
            <ul class="obody flexAndCenter" style="border-bottom:none">
              <!--状态-->
              <li class="oNO">
                <div class="flexAndCenter">
                  <input v-model="item.checked" style="margin-right:10px" type="checkbox"
                         @change="itemCheckedChange(item)"/>
                  <div>
                    <div v-for="(statusItem,statusIndex) in item.status" :key="statusIndex" class="fontSize12">
                      {{ statusItem }}
                    </div>
                  </div>
                </div>
              </li>
              <!--照片-->
              <li class="pic">
                <el-popover placement="right" trigger="hover">
                  <img :src="item.link"
                       style="width: 300px;height: 300px;"
                  />
                  <img slot="reference"
                       :src="item.link"
                       class="cursorPointer"
                       style="width: 50px;height: 50px;"
                  />
                </el-popover>
              </li>
              <!--商品名称-->
              <li class="submitTime">
                <div :title="item.item_name" class="u-line-3" style="width: 200px">{{ item.item_name }}</div>
              </li>
              <!--商品SKU-->
              <li class="datePresentation">
                <div :title="item.item_name" class="u-line-3" style="width: 100px">{{ item.sku }}</div>
              </li>
              <!--ASIN/FNSKU-->
              <li class="datePresentation">
                <div :title="item.item_name" class="u-line-3" style="width: 100px">{{
                    item.asin
                  }}<br>/{{ item.fn_sku }}
                </div>
              </li>
              <!--可售量-->
              <li class="receiverName">
                <div>{{ item.quantity_available }}</div>
              </li>
              <!--补货链接-->
              <li class="replenishmentLink">
                <div class="replenishmentLinkContainer">
                  <div class="replenishmentLinkNum">{{ $fanyi('补货链接') }}：{{
                      item.amazon_replenishment_count
                    }}
                  </div>
                  <div v-show="item.showStatus === false" class="showChildrenBtn"
                       @click="item.showStatus = !item.showStatus">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                  <div v-show="item.showStatus === true" class="showChildrenBtn"
                       @click="item.showStatus = !item.showStatus">
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <div class="addLinkBtn" @click="add(item)">{{ $fanyi('新增链接') }}</div>
                </div>
              </li>
            </ul>
            <div v-show="item.showStatus === true">
              <div class="ordersListHeader">
                <div class="ordersLink">{{ $fanyi("链接") }}</div>
                <div class="goodsImage">{{ $fanyi("图片") }}</div>
                <div class="goodsDetail">{{ $fanyi("属性") }}</div>
                <div class="goodsRemark">{{ $fanyi("备注") }}</div>
                <div class="goodsOperation">{{ $fanyi("操作") }}</div>
              </div>
              <div>
                <div v-for="(orderItem, orderIndex) in item.amazon_replenishment" :key="orderIndex"
                     class="ordersListFooter">
                  <!--链接-->
                  <div :class="orderItem.warehousing==1?'newOrdersLink':'ordersLink'">
                    <div v-if="orderItem.warehousing==1" class="flexAndCenter" style="margin-bottom: 17px;">
                      <el-switch
                          v-model="orderItem.is_default"
                          :active-value="1"
                          :inactive-value="0"
                          active-color="#13ce66"
                          @change="updateDefault($event,item,orderItem)">
                      </el-switch>
                    </div>
                    <div class="linkContainer">
                      <el-link :href="orderItem.goods_link" :underline="false" class="u-line-2" target="_blank">
                        {{ orderItem.goods_link }}
                      </el-link>
                    </div>
                  </div>
                  <!--图片-->
                  <div class="goodsImage">
                    <el-popover placement="right" trigger="hover">
                      <img :src="orderItem.image"
                           style="width: 300px;height: 300px;"
                      />
                      <img slot="reference"
                           :src="orderItem.image"
                           class="cursorPointer"
                           style="width: 60px;height: 60px;"
                      />
                    </el-popover>
                  </div>
                  <!--属性-->
                  <div class="goodsDetail">
                    <div>
                      <el-popover placement="top" trigger="hover">
                        <div>
                          <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex">
                            {{ detailItem.key }}：{{ detailItem.value }}
                          </div>
                        </div>
                        <div slot="reference">
                          <div v-for="(detailItem, detailIndex) in orderItem.detail" :key="detailIndex" class="u-line">
                            {{ detailItem.key }}：{{ detailItem.value }}
                          </div>
                        </div>
                      </el-popover>
                    </div>
                  </div>
                  <!--备注-->
                  <div class="goodsRemark">
                    <div>{{ orderItem.remark }}</div>
                  </div>
                  <!--操作-->
                  <div class="goodsOperation">
                    <div class="btnList">
                      <div @click="edit(item,orderItem)">{{ $fanyi("编辑") }}</div>
                      <div @click="deleteLink(orderItem)">{{ $fanyi("删除") }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="tableData.length === 0 && loadingStatus === false" class="tableDataNullContainer">
          {{ $fanyi("暂无数据") }}
        </div>
      </div>
      <div class="flexAndCenter pagination" style="padding: 30px 0;justify-content: center">
        <el-pagination :current-page="form.page" :page-size="form.pageSize" :page-sizes="[50, 100, 200, 300]"
                       :total="total"
                       layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <replenishmentLink ref="linkRef"/>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="skuErrorDialogVisible"
        center
        title="注意"
        width="656px">
      <div class="editGoodsContainer">
        <div>
          <div v-for="(item,index) in skuErrorList" :key="index">{{ item }}</div>
        </div>
        <div style="margin-top: 40px">以上SKU商品没设置亚马逊补货入仓商品，无法生成订单，请确认。</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import replenishmentLink from "./components/link.vue";
import Dialog from "../../../../components/public/Dialog";

export default {
  data() {
    return {
      form: {
        keyword: '',
        status: '',
        sku: '',
        page: 1,
        pageSize: 50,
      },
      checked: false,
      checkIdList: [],
      linkFrom: {
        inventory_id: '',
        goods_link: '',
        image: '',
        goods_id: '',
        detail: [],
        sku_id: '',
        price: '',
        warehousing: true,
        remark: '',
        shop_id: '',
        shop_name: '',
        goods_title: '',
        spec_id: '',
      },
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        txtcenter: 'center',
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      dialogTitle: this.$fanyi("新增"),
      skuErrorList: [],
      skuErrorDialogVisible: false,
      statusOption: [{label: '出品中', value: 'DISCOVERABLE'}, {label: '停止中(在庫切れ)', value: 'BUYABLE'}],
      total: 0,
      tableData: [],
      oldTableData: [],
      time: null,
      loadingStatus: null,
    };
  },
  components: {
    replenishmentLink,
    Dialog
  },
  created() {
    this.getData(this.form);
    this.$api.getSyncDate().then((res) => {
      this.time = res.data.synchronization_time
    })
  },
  methods: {
    // 获取数据
    getData(data) {
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.loadingStatus = true;
      this.checked = false;
      if (this.tableData.length > 0) {
        this.tableData.forEach((tableItem) => {
          if (tableItem.checked && tableItem.stock_quantity == true) {
            tableItem.amazon_replenishment.forEach((item) => {
              if (item.is_default == 1) {
                this.checkIdList.push(item.inventory_id)
              }
            })
          }
        })
        this.checkIdList = Array.from(new Set(this.checkIdList))
      }
      this.$api.inventoryReportList(data).then((res) => {
        this.loading.close();
        this.loadingStatus = false;
        if (res.code != 0) {
          return
        }
        res.data.data.forEach((item) => {
          item.showStatus = false;
          item.checked = false;
          item.amazon_replenishment.forEach((items) => {
            if (typeof items.detail === 'string') {
              items.detail = JSON.parse(items.detail);
            }
            items.checked = false;
          })
        })
        this.tableData = res.data.data;
        if (this.checkIdList.length > 0) {
          this.checkIdList.forEach((item) => {
            this.tableData.forEach((tableItem) => {
              tableItem.amazon_replenishment.forEach((items) => {
                if (items.inventory_id == item) {
                  tableItem.showStatus = false;
                  tableItem.checked = true;
                }
              })
            })
          })
        }
        this.total = res.data.total;
        this.loadingStatus = false;
        this.$forceUpdate();
      })
    },
    //同步库存
    synchronization() {
      this.$api.storeGoods().then((res) => {
        if (res.code != 0) {
          return
        }
        this.$message.success(res.msg)
        this.$api.getSyncDate().then((res) => {
          this.time = res.data.synchronization_time;
          this.getData(this.form);
        })
      })
    },
    checkedChange() {
      this.tableData.forEach((item) => {
        item.checked = this.checked;
        item.showStatus = this.checked;
      })
    },
    updateDefault(e, item, orderItem) {
      if (item.amazon_replenishment.length == 0 || e == 0) {
        orderItem.is_default == 0 ? orderItem.is_default = 1 : orderItem.is_default = 0
        this.$message.error(this.$fanyi('必须要有一个默认补货下单的商品链接'));
      } else {
        this.$api.replenishmentStatus({
          inventory_id: orderItem.inventory_id,
          id: orderItem.id,
          is_default: e
        }).then((res) => {
          if (res.code != 0) {
            orderItem.is_default == 0 ? orderItem.is_default = 1 : orderItem.is_default = 0
            this.$message.error(res.msg);
            return
          }
          this.$message.success(res.msg);
          this.tableData.forEach((tableItem) => {
            if (orderItem.inventory_id == tableItem.id) {
              tableItem.amazon_replenishment.forEach((amazonItem) => {
                if (amazonItem.id == orderItem.id) {
                  amazonItem.is_default = 1
                } else {
                  amazonItem.is_default = 0
                }
              })
            }
          })
        })
      }
    },
    itemCheckedChange(item) {
      item.showStatus = item.checked;
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData(this.form)
    },
    //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form)
    },
    //补货链接添加
    add(val) {
      if (val.amazon_replenishment.length < 3) {
        this.dialogTitle = this.$fanyi("新增");
        this.linkFrom = {
          inventory_id: val.id,
          goods_link: '',
          image: '',
          goods_id: '',
          detail: [],
          sku_id: '',
          price: '',
          warehousing: true,
          remark: '',
          shop_id: '',
          shop_name: '',
          goods_title: '',
          spec_id: '',
          is_default: 1,
        }
        this.$refs.linkRef.dialogVisible = true;
      } else {
        this.$message.error(this.$fanyi('超过补货链接最大数量'))
      }
    },
    //提出订单
    placeAnOrder() {
      let arr = [];
      if (this.checkIdList.length > 0) {
        this.checkIdList.forEach((item) => {
          arr.push(item)
        })
      }
      this.skuErrorList = [];
      foo:for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].checked === true && (this.tableData[i].stock_quantity != false && this.tableData[i].amazon_replenishment.length != 0)) {
          for (let j = 0; j < this.tableData[i].amazon_replenishment.length; j++) {
            if (this.tableData[i].amazon_replenishment[j].is_default == 1) {
              arr.push(this.tableData[i].amazon_replenishment[j].inventory_id)
              continue foo
            }
            if (j === this.tableData[i].amazon_replenishment.length - 1 && this.tableData[i].amazon_replenishment[j].is_default == 0) {
              this.skuErrorList.push(`FNSKU：${this.tableData[i].fn_sku}`)
            }
          }
        } else if (this.tableData[i].checked === true && (this.tableData[i].stock_quantity == false || this.tableData[i].amazon_replenishment.length == 0)) {
          this.skuErrorList.push(`FNSKU：${this.tableData[i].fn_sku}`)
        }
      }
      if (this.skuErrorList.length > 0) {
        this.skuErrorDialogVisible = true;
        return
      }
      arr = Array.from(new Set(arr))
      if (arr.length > 100) {
        return this.$message.warning(this.$fanyi("一个订单最多添加100个商品"));
      }
      if (arr.length === 0) {
        return this.$message.warning(this.$fanyi("请选择要提出订单的商品"));
      }
      if (location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) {
        __bl.sum('通过亚马逊库存报告下单的商品个数统计', arr.length);
      }
      let id = arr.join(",");
      let url = `/OrderDetails?id=${id}&type=temporary&isAmazon=true`;
      window.open(url);
    },
    //补货链接修改
    edit(item, orderItem) {
      this.dialogTitle = this.$fanyi("编辑");
      this.linkFrom = {
        id: orderItem.id,
        inventory_id: item.id,
        goods_link: orderItem.goods_link,
        image: orderItem.image,
        goods_id: orderItem.goods_id,
        detail: orderItem.detail,
        sku_id: orderItem.sku_id,
        price: orderItem.price,
        warehousing: orderItem.warehousing == 1 ? true : false,
        remark: orderItem.remark,
        shop_id: orderItem.shop_id,
        shop_name: orderItem.shop_name,
        goods_title: item.item_name,
        spec_id: orderItem.spec_id,
        is_default: orderItem.is_default
      }
      let timestamp = Math.floor(new Date().valueOf() / 1000);
      let sign = this.$fun.setToolValue(timestamp);
      this.$api.EuropeKeywordSearchProduct({
        keywords: this.linkFrom.goods_link,
        timestamp: timestamp,
        sign: sign
      }).then((result) => {
            if (result.code != 0) {
              this.$message.error(result.msg);
              return
            }
            result.data.result.goodsInfo.specification.forEach((specificationItem) => {
              specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
                orderItem.detail.forEach((detailItem) => {
                  if (specificationItem.keyC === detailItem.key && valueCItem.name === detailItem.value) {
                    specificationItem.active = valueCIndex;
                  }
                })
              })
            })
            this.$refs.linkRef.goodsInfo = result.data.result.goodsInfo;
            this.$refs.linkRef.goodsInfo.shop_type = result.data.result.fromPlatform;
            this.$refs.linkRef.dialogVisible = true;
          }
      )
    },
    //补货链接删除
    deleteLink(orderItem) {
      this.$refs.dialog.open(
          this.$fanyi("是否删除该补货链接？"),
          () => {
            this.$api.replenishmentLinkDelete({
              id: orderItem.id
            }).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg);
              this.getData(this.form);
            });
          },
          () => {
          }
      );
    },
  }
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

/deep/ input[type="checkbox"]:checked {
  background: #4A91E9;
}

.downloadBtn {
  width: 100px;
  height: 34px;
  background: #2742B4;
  border-radius: 8px;
  line-height: 34px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}

.tableDataNullContainer {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ordersListHeader {
  display: flex;
  background: #F9F9F9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  div {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    height: 42px;
    line-height: 42px;
  }
}

.ordersLink, .newOrdersLink {
  width: 400px;

  a {
    width: 334px;
    color: #2742B4;
    text-decoration-line: underline;
  }
}

.newOrdersLink {
  display: flex;
  flex-direction: column;
  padding: 8px 0 0 8px;

  .icon {
    height: 28px;
    background: #FFE5C4;
    border-radius: 4px;
    font-size: 12px;
    color: #F08804;
    padding: 0 8px;
    line-height: 28px;
    width: 100px;
  }
}

.linkContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.goodsImage {
  width: 100px;
}

.goodsDetail {
  width: 200px;

  .u-line {
    font-size: 14px;
    color: #333333;
  }
}

.goodsRemark {
  width: 222px;

  div {
    font-size: 14px;
    color: #333333;
  }
}

.goodsOperation {
  width: 180px;

  .btnList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    width: 100%;

    div {
      font-size: 14px;
      text-decoration-line: underline;
      cursor: pointer;
      color: #50C136;
    }

    div:last-child {
      color: #B4272B;
    }
  }
}

.ordersListFooter {
  display: flex;
  background: #FFF8EE;
  height: 120px;

  .goodsImage, .goodsOperation, .goodsRemark, .goodsDetail, .ordersLink {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#EmployeesAccount {
  background: #ffffff;
  border-radius: 10px;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .flexAndCenter {
      div:last-child {
        width: 100px;
        height: 30px;
        background: #B4272B;
        border-radius: 6px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-size: 12px;
      }
    }
  }

  .tabsRightContainer {
    height: 40px;
    border-bottom: 1px solid #B4272B;
    margin-top: -14px;
    flex: 1;
  }

  /deep/ .el-tabs__header {
    border-bottom: none;
  }

  /deep/ .is-active {
    border-top: 1px solid #B4272B !important;
    border-right: 1px solid #B4272B !important;
    border-left: 1px solid #B4272B !important;
    border-bottom: 1px solid transparent !important;
  }

  /deep/ .el-tabs__item {
    border-radius: 10px 10px 0 0;
    border-top: 1px solid #DFDFDF;
    border-left: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #B4272B;
  }

  /deep/ .el-tabs__nav {
    border: none;
  }

  .orderStatusContainer {
    margin: 26px 26px 0;
    position: relative;

  }

  .orderScreenContainer {
    margin-left: 26px;
    padding: 15px 0;
    margin-right: 26px;
    border-bottom: 1px solid #DFDFDF;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancelBtnListContainer {
      width: 100px;
      height: 30px;
      background: #B4272B;
      border-radius: 6px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      margin-left: 10px;
    }

    .orderKeywordsContainer {
      /deep/ .el-input__inner {
        width: 160px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 6px;
        line-height: 30px;
      }

      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }

    .orderScreenBtnListContainer {
      div {
        width: 76px;
        height: 30px;
        border-radius: 6px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        color: #fff;
        background: #B4272B;
      }
    }
  }

  .dataBody {
    margin: 20px 26px 0;


    .pic, .receiverName, .upLoad, .orderStatus, .operation {
      width: 100px;
    }

    .datePresentation, .oNO {
      width: 120px;
    }

    .submitTime {
      width: 220px;
    }

    .datePresentation {
      display: flex;
      justify-content: center;
    }

    .replenishmentLink {
      width: 272px;

      .replenishmentLinkContainer {
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .replenishmentLinkNum {
          font-size: 14px;
          color: #333333;
        }

        .showChildrenBtn {
          height: 30px;
          background: #F0F0F0;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          cursor: pointer;
          box-sizing: border-box;
          margin: 0 30px 0 10px;

          .el-icon-arrow-right:before,
          .el-icon-arrow-down:before {
            font-weight: bold;
            font-size: 14px;
          }

          div {
            color: #333333;
            font-size: 14px;
          }
        }

        .addLinkBtn {
          font-size: 12px;
          color: #B4272B;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }

    .head {
      justify-content: center;
      font-size: 14px;
      color: #333;
      text-align: center;

      li {
        background: #F0F0F0;
        height: 56px;
        line-height: 56px;
      }

      li:first-child {
        border-top-left-radius: 10px;
      }

      li:last-child {
        border-top-right-radius: 10px;
      }
    }

    .obody {
      text-align: center;
      min-height: 70px;
      font-size: 14px;
      border-bottom: 1px solid #DFDFDF;
    }
  }

  .pagination {
    /deep/ .el-pagination .el-select .el-input {
      width: 110px;
    }
  }
}
</style>
