<template>
  <div class="shopContainer">
    <el-dialog
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$parent.dialogTitle"
        :visible.sync="dialogVisible"
        center
        width="656px">
      <div class="editGoodsContainer">
        <div class="borderBottom">
          <!--    商品链接-->
          <div class="flexAndCenter margin-bottom-20 goodsLink">
            <div class="label" style="margin-right: 20px">{{ $fanyi("商品链接") }}:</div>
            <el-input v-model="$parent.linkFrom.goods_link"
                      :placeholder="$fanyi('请输入1688链接或taobao链接或RAKUMART链接')"
                      @blur="getProductDetails" @keyup.enter.native="getProductDetails"/>
            <div class="btn" @click="getProductDetails">搜索</div>
          </div>
          <!--亚马逊入仓商品-->
          <!--          <div class="warehouseGoods">-->
          <!--            <el-checkbox v-model="$parent.linkFrom.warehousing">{{ $fanyi("下单后自动标识为入仓商品") }}</el-checkbox>-->
          <!--            <div>?</div>-->
          <!--          </div>-->
          <!--      写真-->
          <div class="flex margin-bottom-20">
            <div class="label" style="width: 60px;margin-right: 20px">{{ $fanyi("写真") }}:</div>
            <div class="goodsImage flexAndCenter">
              <el-popover placement="right" trigger="hover">
                <img :src="$parent.linkFrom.image"
                     style="width: 300px; height: 300px"
                     @click="productDetailsPageJump"/>
                <img slot="reference"
                     :src="$parent.linkFrom.image"
                     style="width: 100px;height: 100px"/>
              </el-popover>
            </div>
          </div>
        </div>
        <!--    网站属性选择-->
        <div class="borderBottom padding-bottom-20">
          <div class="goodsAttributeEditTypeContainer">
            <el-button size="mini" style="background:#c35255;color:#fff">
              {{ $fanyi('网站属性选择') }}
            </el-button>
          </div>
          <div class="AttributeListContainer">
            <div
                v-if="JSON.stringify(goodsInfo)!=='{}'&&goodsInfo!=undefined">
              <div v-for="(item,index) in goodsInfo.specification"
                   :key="index" class="goodsDetailContainer flex">
                <div class="keyContainer">{{ item.keyT }}:</div>
                <div class="valueAttributeListContainer">
                  <div v-for="(items,valueIndex) in item.valueC" :key="valueIndex"
                       :class="item.active===valueIndex?'fontSize14 color333 active':'fontSize14 color333'"
                       @click="updateGoodsSpecificationActive(item,items,valueIndex)">{{ items.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--      单价.元-->
          <div class="flex goodPrice" style="margin-top: 20px">
            <div class="label" style="margin-right: 20px;width: 75px!important;min-width: 75px">{{
                $fanyi("单价.元")
              }}:
            </div>
            <el-input v-model="$parent.linkFrom.price" size="mini"/>
          </div>
        </div>
        <div>
          <!--    下单默认使用-->
          <div class="flex margin-bottom-20" style="margin-top: 20px">
            <div class="label" style="width:61px;margin-right: 20px">{{ $fanyi("下单默认使用") }}:</div>
            <el-switch
                v-model="$parent.linkFrom.is_default"
                :active-value="1"
                :inactive-value="0">
            </el-switch>
          </div>
          <!--    备注-->
          <div class="flex margin-bottom-20" style="margin-top: 20px">
            <div class="label" style="width:61px;margin-right: 20px">{{ $fanyi("备注") }}:</div>
            <el-input v-model="$parent.linkFrom.remark" :placeholder="$fanyi('输入备注')" resize="none"
                      style="width: 500px;"
                      type="textarea"/>
          </div>
          <div class="btnListContainer flexAndCenter">
            <div @click="dialogVisible=false">{{ $fanyi("取消") }}</div>
            <div @click="submitGoodsInfo">{{ $fanyi("保存") }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: '',
      dialogVisible: false,
      goodsInfo: {},
      loading: null,
    }
  },
  created() {
    this.keyupEnter();
  },
  methods: {
    //根据链接获取商品详情
    getProductDetails() {
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      let timestamp = Math.floor(new Date().valueOf() / 1000);
      let sign = this.$fun.setToolValue(timestamp);
      this.$api.EuropeKeywordSearchProduct({
        keywords: this.$parent.linkFrom.goods_link,
        timestamp: timestamp,
        sign: sign
      }).then((result) => {
            this.loading.close();
            if (result.code != 0) {
              this.$message.error(result.msg);
              return
            }
            this.$parent.linkFrom.goods_id = result.data.result.goodsId;
            this.$parent.linkFrom.image = result.data.result.images[0];
            this.$parent.linkFrom.shop_id = result.data.result.shopId;
            this.$parent.linkFrom.shop_name = result.data.result.shopName;
            this.$parent.linkFrom.goods_title = result.data.result.titleC;
            let goodsInventoryOptionStr = '';
            result.data.result.goodsInfo.specification.forEach((item, specificationIndex) => {
              foo:for (let i = 0; i < item.valueC.length; i++) {
                if (i === 0 && specificationIndex !== result.data.result.goodsInfo.specification.length - 1) {
                  item.active = 0;
                  goodsInventoryOptionStr += item.valueC[i].name + "㊖㊎";
                }
                if (specificationIndex === result.data.result.goodsInfo.specification.length - 1) {
                  goodsInventoryOptionStr += `${item.valueC[i].name}`;
                  item.active = 0;
                  for (let j = 0; j < result.data.result.goodsInfo.goodsInventory.length; j++) {
                    if (result.data.result.goodsInfo.goodsInventory[j].keyC === goodsInventoryOptionStr) {
                      result.data.result.goodsInfo.goodsInventory[j].valueC[0].name = item.valueC[i].name;
                      if (item.valueC[i].picUrl !== "") {
                        result.data.result.goodsInfo.goodsInventory[j].valueC[0].picUrl = item.valueC[i].picUrl;
                      }
                      this.$parent.linkFrom.price = result.data.result.goodsInfo.goodsInventory[j].valueC[0].price;
                      break foo;
                    }
                  }
                  goodsInventoryOptionStr = goodsInventoryOptionStr.substr(0, goodsInventoryOptionStr.length - item.valueC[i].name.length);
                }
              }
            });
            this.goodsInfo = result.data.result.goodsInfo;
            this.goodsInfo.shop_type = result.data.result.fromPlatform;
          }
      )
    },
    keyupEnter() {
      document.onkeydown = (e) => {
        let body = document.getElementsByTagName("body")[0];
        if (e.keyCode === 13 && e.target === body) {
          this.getProductDetails();
        }
      };
    },
    deleteData() {
      this.$parent.linkFrom = {
        inventory_id: '',
        goods_link: '',
        image: '',
        goods_id: '',
        detail: [],
        sku_id: '',
        price: '',
        warehousing: true,
        remark: '',
        shop_id: '',
        shop_name: '',
        goods_title: '',
        spec_id: '',
      };
      this.goodsInfo = {};
    },
    //更新选中属性
    updateGoodsSpecificationActive(item, items, index) {
      item.active = index;
      this.$forceUpdate();
      if (items.picUrl !== '') {
        this.$parent.linkFrom.image = items.picUrl;
      }
    },
    //商品详情页跳转
    productDetailsPageJump() {
      if (JSON.stringify(this.goodsInfo) !== '{}') {
        this.$fun.toCommodityDetails(this.$parent.linkFrom.goods_id, this.goodsInfo.shop_type)
      }
    },
    //补货链接追加或修改
    submitGoodsInfo() {
      this.$parent.linkFrom.detail = [];
      let str = '';
      this.goodsInfo.specification.forEach((item) => {
        if (item.active == undefined) {
          item.active = 0;
        }
        str += item.valueC[item.active].name + "㊖㊎"
        this.$parent.linkFrom.detail.push({
          key: item.keyC,
          value: item.valueC[item.active].name,
        })
      })
      str = str.substr(0, str.length - 2);
      this.goodsInfo.goodsInventory.forEach((item) => {
        if (item.keyC === str) {
          this.$parent.linkFrom.sku_id = item.valueC[0].skuId;
          this.$parent.linkFrom.spec_id = item.valueC[0].specId;
        }
      })
      this.$parent.linkFrom.detail = JSON.stringify(this.$parent.linkFrom.detail);
      this.$parent.linkFrom.warehousing == true ? this.$parent.linkFrom.warehousing = 1 : this.$parent.linkFrom.warehousing = 0
      this.$api.replenishmentLinkSave(this.$parent.linkFrom).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg))
        this.goodsInfo = {};
        this.$parent.getData(this.$parent.form)
        this.dialogVisible = false;
      });
    },
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}`).then(_ => {
        done();
        this.deleteData();
      }).catch(_ => {

      });
    },
  },
}
</script>

<style lang="scss" scoped>

.editGoodsContainer {
  margin-top: 40px;

  .borderBottom {
    border-bottom: 1px solid #DFDFDF;
  }

  .priceContainer {
    width: 180px;
    height: 30px;
    background: #F9F9F9;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    padding-left: 9px;
  }

  .label {
    font-size: 14px;
    color: #333333;
    text-align: right;
    width: 90px !important;
    min-width: 90px;
  }

  .width71 {
    width: 71px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .padding-bottom-20 {
    padding-bottom: 20px;
  }

  .margin-right-35 {
    margin-right: 35px;
  }

  .btnListContainer {
    justify-content: center;

    div {
      width: 120px;
      height: 34px;
      background: #fff;
      border: 1px solid #B4272B;
      border-radius: 8px;
      font-size: 14px;
      color: #B4272B;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
    }

    div:last-child {
      color: #fff;
      background: #B4272B;
      margin-left: 26px;
    }
  }

  .goodsLink, .goodPrice {
    /deep/ .el-input__inner {
      width: 440px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
    }

    .btn {
      background: #B4272B;
      width: 60px;
      height: 30px;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      color: #fff;
      border-radius: 3px;
    }
  }

  .warehouseGoods {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 110px;

    /deep/ input[type="checkbox"] {
      display: none;
    }

    div {
      width: 16px;
      height: 16px;
      background: #FFFFFF;
      border-radius: 50%;
      border: 1px solid #B4272B;
      font-size: 12px;
      color: #B4272B;
      cursor: pointer;
      margin-left: 9px;
      text-align: center;
      line-height: 13px;
    }
  }

  .goodsTitle {
    width: 500px;
    height: 70px;
    background: #F9F9F9;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    padding: 8px 3px 0 9px;
    font-size: 14px;
    color: #333333;
  }

  .goodsImage {
    width: 116px;
    height: 116px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    position: relative;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
    }
  }

  .updateGoodsImageIcon {
    width: 26px;
    height: 16px;
    background: #B4272B;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9;
  }

  .goodsAttributeEditTypeContainer {
    margin-top: 36px;

    .el-button + .el-button {
      margin-left: 26px;
    }
  }

  .goodsDetailContainer {
    .keyContainer {
      margin-top: 20px;
      font-size: 14px;
      color: #333333;
      text-align: right;
      width: 75px !important;
      min-width: 75px;
    }

    .valueAttributeListContainer {
      display: flex;
      flex-wrap: wrap;

      div {
        padding: 8px 16px;
        border-radius: 3px;
        box-sizing: border-box;
        border: 1px solid #DFDFDF;
        margin: 20px 0 0 20px;
        cursor: pointer;
      }

      .active {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }

  .artificialAttributeTitle {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #F0F0F0;
    margin-top: 20px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #333;
    font-size: 14px;

    div:first-child {
      width: 220px;
    }

    div:nth-child(2n) {
      width: 316px;
    }

    div:last-child {
      width: 60px;
    }
  }

  .artificialAttributeEditContainer {
    border-bottom: 1px solid #DFDFDF;

    .artificialAttributeEditLeftContainer {
      padding: 10px 8px;
    }

    .artificialAttributeEditRightContainer {
      width: 60px;
      justify-content: center;
    }
  }

  .tagListContainer {
    justify-content: space-between;

    /deep/ .el-input__inner {
      width: 135px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
      line-height: 30px;
    }

    /deep/ .el-input__icon {
      line-height: 30px;
    }

    .oldTagBtn {
      width: 100px;
      height: 30px;
      background: #B4272B;
      border-radius: 3px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 14px;
    }

    .optionListContainer {
      display: flex;
      flex-wrap: wrap;
      width: 600px;

      .optionContainer {
        margin-right: 13px;
        margin-bottom: 21px;

        .optionLabel {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }

  .dialogContainer {
    /deep/ .el-dialog {
      padding: 30px;
    }

    /deep/ .el-dialog__header {
      padding: 0;
      display: flex;
      justify-content: center;

      .el-dialog__title {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        padding-bottom: 7px;
        border-bottom: 4px solid #B4272B;
        text-align: center;
      }

      .el-dialog__headerbtn {
        top: 30px;
        font-size: 19px;

        .el-dialog__close {
          color: #B4272b;
        }
      }
    }

    .selectTagBtnContainer {
      margin-bottom: 20px;

      div {
        width: 120px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 3px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        line-height: 30px;
        text-align: center;
      }

      .checkedTagTypeContainer {
        color: #fff;
        background: #B4272B;
        border: 1px solid #B4272B;
      }
    }

    .tagType {
      width: 160px;
    }

    .goodsName {
      width: 336px;
      line-height: 20px !important;
    }

    .selectTag {
      width: 100px;
      justify-content: center;

      div {
        width: 60px;
        height: 30px;
        background: #B4272B;
        border-radius: 3px;
        line-height: 30px !important;
        text-align: center !important;
        color: #fff !important;
        font-size: 14px !important;
        justify-content: center;
        cursor: pointer;
      }
    }

    .oldTagTableHeader {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: #F0F0F0;
      height: 46px;

      div {
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        color: #333;
      }
    }

    .oldTagTableFooterLi {
      height: 50px;
      border-bottom: 1px solid #DFDFDF;

      div {
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        color: #333;
      }
    }
  }

  .addAttributeText {
    margin-top: 12px;
    text-decoration: underline;
    color: #50C136;
    cursor: pointer;
  }
}
</style>
